import React from 'react';

import { useMicrofrontendsRenderTreeManager } from 'src/contexts/microfrontendsRenderTreeManager';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';

import {
  WebVitalsProvider,
  enableProfilingMetrics,
  initializeProfilingMetrics
} from '@clientos/web-vitals-provider';

import { getBranchLevel, mfeTree } from 'src/utils/webProfiler';

initializeProfilingMetrics((entry) => {
  if (!window.profilingRecords) {
    window.profilingRecords = [];
  }
  const tree = mfeTree.get(entry.reference);
  window.profilingRecords.push({
    ...entry,
    tree,
    assetReference: entry.reference
  });
});

const WebProfiler: React.FC = ({ children }) => {
  const { getBranchOrigin, assetReference } =
    useMicrofrontendsRenderTreeManager();
  const { northboundAPIs } = useDependencyManagerContext();

  React.useEffect(() => {
    enableProfilingMetrics();
  }, []);

  React.useEffect(() => {
    let origin = getBranchOrigin();
    const tree = [origin.assetReference];
    while (origin.parentBranch) {
      tree.unshift(origin.parentBranch.assetReference);
      origin = origin.parentBranch;
    }
    mfeTree.set(assetReference, tree);
  }, [assetReference, getBranchOrigin]);

  const isMainContent = React.useMemo(
    () => getBranchLevel(northboundAPIs, getBranchOrigin) <= 1,
    [northboundAPIs, getBranchOrigin]
  );

  if (isMainContent) {
    return (
      <WebVitalsProvider reference={assetReference}>
        {children}
      </WebVitalsProvider>
    );
  }

  return <>{children}</>;
};

export default WebProfiler;
