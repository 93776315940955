import styled from 'styled-components';
import StyleSupportWrapper from '../StyleSupportWrapper';

export const Container = styled(StyleSupportWrapper)<{ color: string }>`
  display: 'contents';
  ${({ color }) => color && `border: 1px solid ${color}`};
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: fit-content;
  z-index: 99999999;
  pointer-events: none;
`;

export const OverlayContent = styled.div`
  /* background: ${({ color }) => color || '#fff5'}; */
  background: #fff8;
  color: ${({ color }) => color || '#000'};
  /* color: #fff; */
  border: 1px solid ${({ color }) => color || '#000'};
  padding: 5px;
  border-radius: 3px;
  white-space: nowrap;
  pointer-events: none;
`;
