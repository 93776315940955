import type { CommonsNorthboundAPIsType } from 'src/contexts/dependencyManager/utils/getCommonsNorthboundAPIs/types';
import type { RenderBranchOriginType } from 'src/contexts/microfrontendsRenderTreeManager/types';
import type { Subscriber } from '@clientos/commons-northbound-api/dist/services/JWeb/types';

import * as T from './types';
import { DefaultMap } from 'src/utils/DefaultMap';

export const ASSET_REFERENCE_SYMBOL = Symbol('assetReference');

export function getBranchLevel(
  northboundAPIs: CommonsNorthboundAPIsType,
  getBranchOrigin: () => RenderBranchOriginType
): number {
  const mfeRouterState = northboundAPIs.v1.microfrontendRouter.getState();
  const branchOrigin = getBranchOrigin();
  if (branchOrigin?.branchLevel === 0) {
    return 0;
  }

  const layoutAssetReference = mfeRouterState.layout?.assetReference;
  const contentAssetReference = mfeRouterState.content?.assetReference;

  const isContent = contentAssetReference === branchOrigin?.assetReference;

  let branchLevel = 0;
  if (layoutAssetReference && isContent) {
    let branch = branchOrigin;
    while (branch) {
      if (contentAssetReference === branch?.assetReference) {
        branchLevel++;
      }
      branch = branch.parentBranch;
    }
  }

  return branchLevel;
}

// Find out if an element is contained inside an MFE
export function isWithinMFE(
  el: T.MaybeAssetReferenceElement,
  assetReference: string
): boolean {
  if (!el) {
    return null;
  }
  let container: T.MaybeAssetReferenceElement = el;
  do {
    if (container?.[ASSET_REFERENCE_SYMBOL] === assetReference) {
      return true;
    }
    container = container.parentElement;
  } while (container);
  return false;
}

// Find out what's the mfe container of a given node.
export function findAssetReference(
  el: T.MaybeAssetReferenceElement
): string | null {
  if (!el) {
    return null;
  }
  let container: T.MaybeAssetReferenceElement = el;
  while (container && !container[ASSET_REFERENCE_SYMBOL]) {
    container = container.parentElement;
  }
  return container ? container[ASSET_REFERENCE_SYMBOL] : null;
}

export function getElementsFromPerformanceEntry(
  entry: PerformanceEntry
): Element[] {
  if ('element' in entry) {
    return [(entry as LargestContentfulPaint).element];
  }

  if ('target' in entry) {
    return [(entry as InputEvent).target as Element];
  }

  return (entry as LayoutShift).sources.map((source) => source.node as Element);
}

export function groupEntriesByAssetReference(
  entriesList: PerformanceEntry[]
): Map<string, PerformanceEntry[]> {
  const entriesByAssetReference = new Map<string, PerformanceEntry[]>();

  for (const performanceEntry of entriesList) {
    const elements = getElementsFromPerformanceEntry(performanceEntry);
    for (const element of elements) {
      const assetReference = findAssetReference(element);
      if (!assetReference) {
        continue;
      }
      const entries = entriesByAssetReference.get(assetReference) || [];
      entries.push(performanceEntry);
      entriesByAssetReference.set(assetReference, entries);
    }
  }

  return entriesByAssetReference;
}

export async function createNavigationCallback(
  cb: T.NavigationCallback
): Promise<void> {
  try {
    const EventService = window.JWeb?.Plugins.EventService;
    const subscriber = (await EventService?.createSubscriber()) as Subscriber;
    await subscriber.subscribe(
      { eventName: 'shell-global-microfrontend-router' },
      cb
    );
  } catch (err) {
    console.error(`Unable to subscribe to navigation event.`, err.message);
  }
}

export function createTimedCallback(
  cb: () => void,
  timeout: number
): T.TimedCallback {
  let timeoutId = setTimeout(cb, timeout);
  return {
    reset: () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(cb, timeout);
    },
    cancel: () => {
      clearTimeout(timeoutId);
    }
  };
}

export const mfeTree = new DefaultMap<string, string[]>(() => []);

export const reportCallback: T.ReportCallback = (entry: T.ReportEntry) => {
  if (!window.profilingRecords) {
    window.profilingRecords = [];
  }
  window.profilingRecords.push(entry);
};

export function environmentSupportsProfiling(): boolean {
  const supportsAllPerformanceAPIs = ['clearMarks', 'mark', 'measure'].every(
    (apiName) => apiName in window.performance
  );
  if (!supportsAllPerformanceAPIs) {
    console.info(
      `MFE performance profiling is not supported in this environment`
    );
  }
  return supportsAllPerformanceAPIs;
}
