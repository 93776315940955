import React from 'react';
import StyleSupportWrapper from '../StyleSupportWrapper';
import { createColorInstance } from './colors';
import * as T from './types';
import * as S from './styles';
import { useMicrofrontendsRenderTreeManager } from 'src/contexts/microfrontendsRenderTreeManager';
import { RenderBranchType } from 'src/contexts/microfrontendsRenderTreeManager/types';

const firstComponentRef: { update: () => void } = {
  update: () => undefined
};
const colorByIdMap: Map<number, { color: string; isMouseHover: boolean }> =
  new Map();
const debugAssistantClassName = 'clientos-debug-assistant-class-name';
const DebugAssistant: React.FC<T.DebugAssistantProps> = ({
  children,
  assetReference
}) => {
  const { branchLevel, getRenderTree, id } =
    useMicrofrontendsRenderTreeManager();
  const ref = React.useRef<HTMLDivElement>(null);
  const isFirstComponent = branchLevel === 0;
  const [updateCount, setUpdateCount] = React.useState(0);

  React.useEffect(() => {
    // Do not remove this consoles, they are part of this feature
    console.debug(`Rendering lifecycle - Loading ${assetReference}`);

    return () => {
      console.debug(`Rendering lifecycle - Unloading ${assetReference}`);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (isFirstComponent) {
      firstComponentRef.update = () => {
        setUpdateCount((prev) => prev + 1);
      };

      return () => {
        firstComponentRef.update = () => undefined;
      };
    }
  }, [isFirstComponent]);

  React.useEffect(() => {
    if (assetReference && typeof assetReference === 'string') {
      const colorInstance = createColorInstance(assetReference);
      colorByIdMap.set(id, { color: colorInstance.color, isMouseHover: false });

      let isMounted = true;
      const startObserver = async (): Promise<{ disconnect: () => void }> => {
        const defaultResult = { disconnect: () => undefined as void };
        try {
          if (!isMounted) {
            return defaultResult;
          }
          const child = ref.current.firstElementChild as HTMLElement;
          if (!child) {
            return new Promise((resolve) => {
              setTimeout(() => {
                resolve(startObserver());
              }, 100);
            });
          }
          const mouseEnterEvent = () => {
            colorByIdMap.set(id, {
              color: colorInstance.color,
              isMouseHover: true
            });
            firstComponentRef.update();
          };
          const mouseLeaveEvent = () => {
            colorByIdMap.set(id, {
              color: colorInstance.color,
              isMouseHover: false
            });
            firstComponentRef.update();
          };
          child.addEventListener('mouseenter', mouseEnterEvent);
          child.addEventListener('mouseleave', mouseLeaveEvent);

          const setBorder = (element: HTMLElement) => {
            const value = `3px solid ${colorInstance.color}`;
            if (
              element.style.border !== value &&
              isMounted &&
              element.offsetWidth > 0 &&
              element.offsetHeight > 0
            ) {
              element.style.border = value;
            }
          };

          const updateOverlayProps = () => {
            setBorder(child);

            const setBorderForFloatingChildren = (element: HTMLElement) => {
              if (
                getComputedStyle(element).position === 'fixed' ||
                getComputedStyle(element).position === 'absolute' ||
                getComputedStyle(element).position === 'sticky'
              ) {
                setBorder(element);
              }
              if (
                element === child ||
                !element?.classList?.contains?.(debugAssistantClassName)
              ) {
                Array.from(element.children).forEach((child) => {
                  setBorderForFloatingChildren(child as HTMLElement);
                });
              }
            };

            setBorderForFloatingChildren(child);
          };

          // const thisObserver = new ResizeObserver(() => {
          //   updateOverlayProps();
          // });
          // thisObserver.observe(child, {
          //   box: 'content-box'
          // });

          let timeout: NodeJS.Timeout;
          const thisObserver = new MutationObserver((mutations) => {
            const isRelevantChangeType = mutations.find(
              (mutation) =>
                mutation.type === 'childList' || mutation.type === 'attributes'
            );
            if (isRelevantChangeType) {
              if (timeout) {
                clearTimeout(timeout);
              }
              timeout = setTimeout(() => {
                updateOverlayProps();
              }, 1000);
            }
          });
          thisObserver.observe(child, {
            attributes: true,
            childList: true,
            subtree: true,
            characterData: true,
            attributeFilter: ['style']
          });

          updateOverlayProps();

          return {
            disconnect: () => {
              thisObserver.disconnect();
              child.removeEventListener('mouseenter', mouseEnterEvent);
              child.removeEventListener('mouseleave', mouseLeaveEvent);
            }
          };
        } catch (error) {
          console.error(error);
          return defaultResult;
        }
      };

      const observerPromise = startObserver();

      return () => {
        isMounted = false;
        observerPromise
          .then((observer) => observer.disconnect())
          .catch((error) => {
            console.error(error);
          });
        colorByIdMap.delete(id);
        colorInstance.clear();
      };
    }
  }, [assetReference, id, branchLevel]);

  const mouseOverRenderTree = React.useMemo(() => {
    if (!isFirstComponent || !updateCount) return undefined;

    const getBranchDetails = (
      branch: RenderBranchType
    ): T.MouseOverRenderTreeType => {
      if (!branch) return undefined;

      const nestedBranch = branch?.branches
        ?.map?.(getBranchDetails)
        ?.filter?.(Boolean);
      const { color, isMouseHover } = colorByIdMap?.get?.(branch?.id) || {};
      if (isMouseHover || nestedBranch?.length) {
        const colors: Record<string, any> = {};
        colorByIdMap.forEach((value, key) => {
          colors[key] = value;
        });
        return {
          assetReference: branch?.assetReference,
          level: branch?.branchLevel,
          nestedBranch,
          color
        };
      } else {
        return undefined;
      }
    };

    return getBranchDetails(getRenderTree());
  }, [getRenderTree, updateCount, isFirstComponent]);

  return (
    <StyleSupportWrapper
      style={{
        display: 'contents'
      }}
    >
      <StyleSupportWrapper
        style={{
          display: 'contents'
        }}
        className={debugAssistantClassName}
        ref={ref}
      >
        {children}
      </StyleSupportWrapper>
      {mouseOverRenderTree && (
        <S.Overlay>
          <OverlayMfeDetails mouseOverRenderTree={mouseOverRenderTree} />
        </S.Overlay>
      )}
    </StyleSupportWrapper>
  );
};

const OverlayMfeDetails: React.FC<{
  mouseOverRenderTree: T.MouseOverRenderTreeType;
}> = ({ mouseOverRenderTree }) => {
  return (
    <S.OverlayContent color={mouseOverRenderTree?.color}>
      {mouseOverRenderTree?.assetReference}
      {mouseOverRenderTree?.nestedBranch?.map?.((branch) => (
        <OverlayMfeDetails
          key={branch?.assetReference}
          mouseOverRenderTree={branch}
        />
      ))}
    </S.OverlayContent>
  );
};

export default DebugAssistant;
