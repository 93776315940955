import React, { useMemo } from 'react';
import { DependencyManagerProvider } from 'src/contexts/dependencyManager';
import * as T from './types';
import ErrorBoundary from 'src/components/ErrorBoundary';
import { MicrofrontendsRenderTreeManagerProvider } from 'src/contexts/microfrontendsRenderTreeManager';
import { ErrorBoundaryPropsType } from 'src/components/ErrorBoundary/types';
import { LogManagerProvider } from 'src/contexts/logManager';
import WebProfiler from 'src/components/WebProfiler';
import DebugAssistant from 'src/components/DebugAssistant';
import { DebugAssistantProps } from 'src/components/DebugAssistant/types';

const AppComponent: React.FC<T.AppPropsType> = ({
  children,
  errorHandler,
  performanceMetrics,
  assetReference,
  logger,
  firstComponentName,
  firstComponentDisplayName,
  debugAssistant
}) => {
  const errorBoundary = useMemo<T.AppComponentDetailsMemoType>(() => {
    if (errorHandler?.enable) {
      const props: ErrorBoundaryPropsType = {
        showError: errorHandler?.showError
      };

      return {
        Component: ErrorBoundary,
        props
      };
    }
    return { Component: React.Fragment, props: {} };
  }, [errorHandler]);

  const webProfiler = useMemo<T.AppComponentDetailsMemoType>(() => {
    if (performanceMetrics?.enable) {
      return {
        Component: WebProfiler,
        props: {}
      };
    }
    return { Component: React.Fragment, props: {} };
  }, [performanceMetrics]);

  const debugAssistantComponentDetails =
    useMemo<T.AppComponentDetailsMemoType>(() => {
      if (debugAssistant?.mfeIdentifier) {
        const props: DebugAssistantProps = {
          assetReference
        };

        return {
          Component: DebugAssistant,
          props
        };
      }
      return { Component: React.Fragment, props: {} };
    }, [assetReference, debugAssistant]);

  return (
    <MicrofrontendsRenderTreeManagerProvider
      assetReference={assetReference}
      firstComponentName={firstComponentName}
      firstComponentDisplayName={firstComponentDisplayName}
    >
      <LogManagerProvider {...logger}>
        <debugAssistantComponentDetails.Component
          {...debugAssistantComponentDetails?.props}
        >
          <errorBoundary.Component {...errorBoundary?.props}>
            <webProfiler.Component>{children}</webProfiler.Component>
          </errorBoundary.Component>
        </debugAssistantComponentDetails.Component>
      </LogManagerProvider>
    </MicrofrontendsRenderTreeManagerProvider>
  );
};

const App: React.FC<T.AppPropsType> = (props) => {
  return (
    <DependencyManagerProvider>
      <AppComponent {...props} />
    </DependencyManagerProvider>
  );
};

export default App;
