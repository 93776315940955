import React, { memo, useMemo } from 'react';
import App from 'src/App';
import * as T from './types';
import { AppPropsType } from 'src/App/types';
import deepCheckValues from 'src/utils/deepCheckValues';
import useAsyncMemo from 'src/hooks/useAsyncMemo';

export default function HOC<
  G extends Record<string, unknown> = Record<string, any>
>(options: T.HOCPropsType): React.FC<G> {
  // Used to Inject assetReference as displayName to the component
  const ComponentLoader: React.FC = ({ children }) => {
    return <>{children}</>;
  };

  const ReactMfeHocWrapperDisplayName =
    typeof options.assetReference === 'string'
      ? `${options.assetReference}___@clientos/react-mfe-hoc`
      : 'ReactMfeHocWrapper';
  const ComponentLoaderDisplayName =
    typeof options.assetReference === 'string'
      ? options.assetReference
      : 'ComponentLoader';

  const ReactMfeHocWrapper: React.FC = (props) => {
    const { data: Component, loading } = useAsyncMemo(async () => {
      const Component = await (async () => {
        if (options?.getComponent) {
          try {
            const result = await options.getComponent();

            return result;
          } catch (error) {
            console.error(error);
          }
        }
      })();

      return Component || options?.Component;
    }, []);

    const errorHandlerProps = useMemo<AppPropsType['errorHandler']>(
      () => ({
        enable: options?.errorHandler?.enable,
        showError: !Component
      }),
      [Component]
    );

    if (loading) {
      return null;
    }

    return (
      <App
        // Provide only primitive values or memoized objects
        errorHandler={errorHandlerProps}
        performanceMetrics={options?.performanceMetrics}
        logger={options?.logger}
        assetReference={options?.assetReference}
        firstComponentName={ReactMfeHocWrapper?.name}
        firstComponentDisplayName={ReactMfeHocWrapperDisplayName}
        debugAssistant={{
          mfeIdentifier: !!options?.debugAssistant?.mfeIdentifier
        }}
      >
        <ComponentLoader>
          {Component && <Component {...props} />}
        </ComponentLoader>
      </App>
    );
  };

  ReactMfeHocWrapper.displayName = ReactMfeHocWrapperDisplayName;
  ComponentLoader.displayName = ComponentLoaderDisplayName;

  const memoCallback = options?.performanceHandler?.deepCompareProps
    ? deepCheckValues
    : undefined;

  return memo(ReactMfeHocWrapper, memoCallback);
}
